













import {Vue, Component} from "vue-property-decorator";
import HeadStateType from "./indexType";

@Component({name: "HeadState"})
export default class HeadState extends Vue implements HeadStateType{
    data = {}

    activated(){
        let { data = "" } = this.$route.query
        let JsonData:any = {}
        try {
            try {
                JsonData = JSON.parse(data as string)
            } catch (e) {
                JsonData = eval(data as string)
            }
            this.data = JsonData
        }catch (e) {
            console.info("转换失败")
        }
    }

    get getStateIcon(){
        let { state = "",data = "" } = this.$route.query
        if ( state == "ok" ){
            return require("@/views/Money/BillStatus/Svg/Succeed.svg")
        }else{
            return require("@/views/Money/BillStatus/Svg/fail.svg")
        }
    }

    get getStatus(){
        let { state = "",data = "" } = this.$route.query
        if ( state == "ok" ){
            return "提现申请成功"
        }else{
            return "提现失败"
        }
    }
}
