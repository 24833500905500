





























import {Vue, Component} from "vue-property-decorator";
import BillStatusType from "./BillStatus";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import HeadState from "@/views/Money/BillStatus/components/HeadState/index.vue";
import AccountSuc from "@/views/Money/BillStatus/components/AccountSuc/index.vue";

@Component({name: "BillStatus",components:{ HeadTop,PullDownUpList,ListBottom,HeadState,AccountSuc }})
export default class BillStatus extends ZoomPage implements BillStatusType{
    data:any = {}

    activated(){
        let { data = "" } = this.$route.query
        let JsonData:any = {}
        try {
            try {
                JsonData = JSON.parse(data as string)
            } catch (e) {
                JsonData = eval(data as string)
            }
            this.data = JsonData
        }catch (e) {
            console.info("转换失败")
        }
    }

    handleClickBtn(){
        this.$router.go(-1)
    }

    get getTitle(){
        let { state = "" } = this.$route.query
        if ( state == "ok" ){
            return "提现成功"
        }else{
            return "提现失败"
        }
    }

    get getTypeIcon(){
        let { data = "" } = this.$route.query
        let JsonData:any = {}
        try{
            try{
                JsonData = JSON.parse(data as string)
            }catch (e) {
                JsonData = eval(data as string)
            }
            if ( JsonData["accountType"] == '1' ){
                return require("@/views/Money/Img/wx.png")
            }else if ( JsonData["accountType"] == '2' ){
                return require("@/views/Money/Img/zfb.png")
            }else {
                return require("@/views/Money/Img/sy.png")
            }
        }catch (e) {
            return require("@/views/Money/Img/wx.png")
        }
    }
}
