












import {Vue, Component} from "vue-property-decorator";
import AccountSucType from "./indexType";
import { accountSuc } from "@/views/Money/BillStatus/Model";

@Component({name: "AccountSuc"})
export default class AccountSuc extends Vue implements AccountSucType{
    billList = accountSuc
}
